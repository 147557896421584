<template>
  <div>
    <div class="mb-2">
      <v-card elevation="2" outlined shaped tile>
        <v-card-text>
          <v-row class="mt-0">
            <v-col cols="12" sm="12" md="12">
              <router-link :to="return_url" class="ml-2 btn_return">
                <b-icon icon="box-arrow-left" aria-hidden="true"></b-icon>
                {{ this.$t("Return") }}
              </router-link>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="12" sm="12" md="7">
              <h4>{{ this.$t("Kilometers") }}</h4>
            </v-col>
            <v-col cols="12" sm="12" md="5">
              <v-alert shaped dark color="info" class="mb-0">
                <v-row>
                  <v-col cols="12" sm="6" md="6" class="text-right"
                    >Total KM :</v-col
                  >
                  <v-col cols="12" sm="6" md="6"
                    >{{ monthly_km_data.totalKM }} KM</v-col
                  >
                </v-row>
                <v-row class="mt-0">
                  <v-col cols="12" sm="6" md="6" class="text-right"
                    >{{ this.$t("Cost") }} KM :</v-col
                  >
                  <v-col cols="12" sm="6" md="6"
                    >{{ monthly_km_data.costperKM }} €</v-col
                  >
                </v-row>
                <hr class="hr_stick" />
                <v-row class="mt-0">
                  <v-col cols="12" sm="6" md="6" class="text-right"
                    >{{ this.$t("Total Amount") }} KM :</v-col
                  >
                  <v-col cols="12" sm="6" md="6">{{
                    currency_format(
                      monthly_km_data.totalKM * monthly_km_data.costperKM
                    )
                  }}</v-col>
                </v-row>
              </v-alert>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="12" sm="12" md="12">
              <Kilometers :isteam="isteam" />
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <v-col cols="12" sm="12" md="7">
              <h4>{{ this.$t("Other Expenses") }}</h4>
            </v-col>
            <v-col cols="12" sm="12" md="5">
              <v-alert shaped dark color="info" class="mb-0">
                <v-row class="mt-0">
                  <v-col cols="12" sm="6" md="6" class="text-right"
                    >{{ this.$t("Total Num Bills") }} :</v-col
                  >
                  <v-col cols="12" sm="6" md="6">{{
                    monthly_other_expenses_data.num_bills
                  }}</v-col>
                </v-row>
                <hr class="hr_stick" />
                <v-row class="mt-0">
                  <v-col cols="12" sm="6" md="6" class="text-right"
                    >{{ this.$t("Total Value of Bills") }} :</v-col
                  >
                  <v-col cols="12" sm="6" md="6">{{
                    currency_format(
                      monthly_other_expenses_data.total_value_bills
                    )
                  }}</v-col>
                </v-row>
              </v-alert>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="12" sm="12" md="12">
              <Otherexpenses :isteam="isteam" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import Kilometers from "@/views/expenses/approval/kilometers";
import Otherexpenses from "@/views/expenses/approval/otherexpenses";

export default {
  name: "monthdetail",
  props: ["isteam", "year", "month", "userId"],
  components: {
    Kilometers,
    Otherexpenses,
  },
  data() {
    return {};
  },
  watch: {},
  computed: {
    ...mapGetters("expenses", [
      "monthly_km_data",
      "monthly_other_expenses_data",
    ]),
    return_url() {
      let url = "/expenses/liquidation/";
      if (this.isteam === "team") {
        url += "team";
      } else {
        url += "my";
      }
      return url;
    },
  },
  methods: {
    init() {},
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
hr.hr_stick {
  margin: 10px 0px 0px 0px;
  background: #fff;
}
.btn_return {
  padding: 10px;
  background: #ddd;
  color: #000 !important;
  border-radius: 10px;
  border: 2px solid #000;
  font-size: 1.2em;
  text-transform: capitalize;
  text-decoration: none !important;
  font-weight: 800;
}
</style>
